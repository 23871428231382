$floori-displays: (
    block,
    flex,
    grid,
    inline,
    inline-block,
    inline-flex,
    inline-grid,
    initial,
    inherit,
    none
);

$floori-flex-direcions: (column, column-reverse, row, row-reverse);

@each $value in $floori-displays {
    .floori-d-#{$value} {
        display: $value;
    }
}

@each $value in $floori-flex-direcions {
    .floori-flex-#{$value} {
        flex-direction: $value;
    }
}

$floori-text-align: (center, left, right, justify);

@each $value in $floori-text-align {
    .floori-text-#{$value} {
        text-align: $value;
    }
}

.floori-w100prc {
    width: 100%;
}

.floori-flex-1 {
    flex: 1;
}

.floori-flex-0 {
    flex: 0;
}
