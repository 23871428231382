/* You can add global styles to this file, and also import other style files */

* {
    margin: 0;
    padding: 0;
}

*,
*:before,
*:after {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    outline: none !important;
}

a,
a:visited {
    outline: none !important;
    text-decoration: none;
}

a:hover,
a:active,
a:focus {
    background: transparent !important;
}

html {
    width: 100dvw;
    max-height: 100vh;
    overflow: hidden;
}

@supports (max-height: 100dvh) {
    html {
        max-height: 100dvh;
    }
}

body {
    overflow-y: hidden;

    floori-root {
        display: flex;
        flex-direction: column;
        height: 100vh;

        main {
            height: inherit;
        }
    }

    @supports (height: 100dvh) {
        floori-root {
            height: 100dvh;
        }
    }
}
