@use 'mixins';
@use 'common/display';
@use 'common/flex';
@use 'common/cursors';
@use 'themes/default/breakpoints';
@use 'include-media';

floori-root {
    height: 100%;
}

.hidden-ui {
    //@include media('<flDesktop') {
    display: none !important;
    //}
}
