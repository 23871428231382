.floori-justify-content-space-between {
  justify-content: space-between;
}

.floori-justify-content-center {
  justify-content: center;
}

.floori-justify-content-end {
  justify-content: flex-end;
}

.floori-align-items-center {
  align-items: center;
}
